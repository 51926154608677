// export const BASE_URL = "http://192.168.18.129:8000";
export const BASE_URL = "https://polobackend.oryzagrains.com";
// export const BASE_URL = "http://43.201.183.66:8000";
// export const BASE_URL = "https://4153-2407-aa80-314-8c01-1a95-7d3e-96a1-cc29.ngrok-free.app"
















